<template>
  <!-- <a href="#" @click="deleteQuestion" class="text-danger">Delete</a> -->
  <button @click="deleteQuestion" class="btn btn-sm btn-outline-secondary mr-2">Delete Question {{ questionIndex + 1 }}</button>
</template>

<script>
import axios from "axios";

export default {
  props: {
    questionUUID: String,
    questionIndex: Number,
  },

  methods: {
    deleteQuestion() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: `This action will delete this question and its answers.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            axios.post("/authoring/questions/delete/" + this.questionUUID).then((res) => {
              if (res.data.status == "success") {
                this.$emit("questionDeleted", this.questionIndex); //this.$store.state.questions.splice(questionIndex, 1);
                this.$swal.fire("Deleted!", res.data.message, "success");
              } else {
                this.$swal.fire("Oops!", res.data.message, "error");
              }
            });
          }
        });
    },
  },
};
</script>

<style scoped>
/* .btn-sm {
  text-transform: uppercase;
  font-size: 11px;
}
.btn-outline-secondary {
  border-color: #d9dfe4;
} */
</style>