<template>
  <!-- <a href="#" @click="deleteAnswer" class="text-danger">Delete</a> -->
  <button @click="deleteAnswer" class="btn btn-sm btn-outline-secondary mr-2">Delete Answer {{ answerIndex + 1 }}</button>
</template>

<script>
import axios from "axios";

export default {
  props: {
    answerUUID: String,
    questionIndex: Number,
    answerIndex: Number,
  },

  methods: {
    deleteAnswer() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: `This action will delete this answer and its answers.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            axios.post("/authoring/answers/delete/" + this.answerUUID).then((res) => {
              if (res.data.status == "success") {
                this.$emit("answerDeleted", { answerIndex: this.answerIndex, questionIndex: this.questionIndex }); //this.$store.state.answers.splice(answerIndex, 1);
                this.$swal.fire("Deleted!", res.data.message, "success");
              } else {
                this.$swal.fire("Oops!", res.data.message, "error");
              }
            });
          }
        });
    },
  },
};
</script>

<style scoped>
/* .btn-sm {
  text-transform: uppercase;
  font-size: 11px;
}
.btn-outline-secondary {
  border-color: #d9dfe4;
} */
</style>