import axios from 'axios'

const toolbar = [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["image", "video", "code-block"]
]

let handleImageAdded = function (file, Editor, cursorLocation, resetUploader) {
    var formData = new FormData();
    //formData.append("image", file);
    formData.append("file", file);
    axios({
        url: "/authoring/questions-answers-image-upload",
        method: "POST",
        data: formData,
    })
        .then((result) => {
            console.log(result.data);

            //let url = result.data.url; // Get url from response
            let url = result.data.fileURL; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
        })
        .catch((err) => {
            console.log(err);
        });
}

export default { toolbar, handleImageAdded }